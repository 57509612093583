import './Catalog.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import img1 from './image/img1.png';

function Catalog() {
    return (
      <div className="container-fluid cathead">
          <div className="row">
              <div className="col-xl-6">

                  <img className="catimg" src={img1} alt=""/>
              </div>

              <div className="col-xl-6">
                  <h1 className="cattext">
                      О нашей компании
                  </h1>
                  <h2 className="catitle">
                      AluCabel (ИП ООО «ALUCABEL PAYRAV») — это современное, инновационное, динамично развивающееся
                      предприятие по производству энергетических проводов и кабелей.
                      <br/>
                      Предприятие расположено в городе Янгиюль, Республики Узбекистан.
                      Завод AluCabel – новый завод и несмотря на свою молодость, предприятие считается очень
                      перспективным и передовым в плане внедрения новых технологий производства. На AluCabel установлено
                      импортное оборудование, что позволяет поддерживать качество продукции на неизменно высоком уровне.
                  </h2>
              </div>
          </div>
      </div>
    );

}

export default Catalog;