import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import alucabell from './image/alucabell.png';



function App() {
  return (
      <div className="App">
        <nav className="navbar navbar-expand-lg psp">
          <div className="container-fluid psp2">
            <a className="navbar-brand" href="#">
              <img className="icon" src={alucabell} alt=""/>
            </a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className="nav-link navtxt active" aria-current="page" href="#">Главный</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link navtxt" href="#">Каталог продуктов</a>
                </li>
                <li className="nav-item dropdown">
                  <a className="nav-link navtxt" href="#" >
                    О Нас
                  </a>

                </li>
                <li className="nav-item">
                  <a className="nav-link navtxt">Контакты</a>
                </li>
              </ul>

                <button className=" navbtn1" type="submit">
                  Search
                </button>

            </div>
          </div>
        </nav>
      </div>

  );
}

export default App;
