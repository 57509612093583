import './Header.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Alucabel from './image/snapedit_1731693685332.jpeg'
import Alucabel1 from './image/snapedit_1731693760585.jpeg'

import Artboard  from './image/Alucabel5.png'

function Header() {
    return(
        <div className="container-fluid header">
            {/*<video autoPlay loop muted className="video-background">*/}
            {/*    <source src={alucabvdd}  type="video/mp4"/>*/}
            {/*    Your browser does not support the video tag.*/}
            {/*</video>*/}


            <div id="carouselExampleCaptions" className="carousel slide">
                <div className="carousel-indicators">
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0"
                            className="active" aria-current="true" aria-label="Slide 1"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1"
                            aria-label="Slide 2"></button>
                    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2"
                            aria-label="Slide 3"></button>
                </div>

                <div className="carousel-inner">

                    <div className="carousel-item active">
                        <img src={Alucabel} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5>AluCabel</h5>
                            <p>На сегодняшний день, мы являемся одним
                                из признанных лидеров отрасли по качеству
                                выпускаемой продукции</p>
                        </div>
                    </div>

                    <div className="carousel-item">
                        <img src={Artboard} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h5>Second slide label</h5>
                            <p>Some representative placeholder content for the second slide.</p>
                        </div>
                    </div>
                    <div className="carousel-item">
                        <img src={Alucabel1} className="d-block w-100" alt="..."/>
                        <div className="carousel-caption d-none d-md-block">
                            <h1>Third slide label</h1>
                            <p>Высоко качественные материалы.</p>
                        </div>
                    </div>
                </div>

                <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleCaptions"
                        data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>

            <button>Close</button>

        </div>
    );
}

export default Header;